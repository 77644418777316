import React from "react";
import gpt from "../assets/gpt.png";
import stock from "../assets/stock.png";
import movie from "../assets/movie.png";
import youtube from "../assets/EndToEndData.png";
import devSecOps from "../assets/devSecOps.png";


const Portfolio = () => {
  const porofolios = [
    {
      id: 1,
      src: gpt,
      url: "https://ai-image-gene-saas.vercel.app/",
      code: "https://github.com/berhane02/ai-image-gene-saas",
      desc: "FullStack generative AI-SAAS WebApp using React, clerk, stripe, Express, Next.js, MySQL Prisma, OpenAI and Replicate API "
    },
    {
      id: 2,
      src: devSecOps,
      url: "https://github.com/berhane02/terraform-devsecops",
      code: "https://github.com/berhane02/terraform-devsecops",
      desc: "Build DevSecOps pipeline in order to understand behavior of bugs and vulnerabilities, like memory leak, deadlock, JVM crash, SQL injection and so on "

    },
    {
      id: 3,
      src: movie,
      url: "https://front-media.vercel.app/",
      code: "https://github.com/berhane02/front-media",
      desc: "FullStack movie review webApp using React, javascript, html, CSS frontEnd and Springboot with MongoDB backend"

    },
    {
      id: 4,
      src: youtube,
      url: "https://github.com/berhane02/Data-Engineering/tree/main/EndToEndYoutubeDataAnalysis",
      code: "https://github.com/berhane02/Data-Engineering/tree/main/EndToEndYoutubeDataAnalysis",
      desc: "End to End youtube data analysis using AWS cloud services S3, pySpark, Glue, Lambda, AWS Athena and QuickSight"

    },{
      id: 5,
      src: stock,
      url: "https://stockpredictionwebapp-prophet.streamlit.app/",
      code: "https://github.com/berhane02/StockPredictionWebApp",
      desc: "Build Stock price prediction data app using Facebook's Prophet forecasting model, Yahoo Finance API and plotly to desplay analysis"

    },/*
    {
      id: 2,
      src: stock,
      url: "https://stockpredictionwebapp-prophet.streamlit.app/",
      code: "https://github.com/berhane02/StockPredictionWebApp",
      desc: "Build Stock price prediction data app using Facebook's Prophet forecasting model, Yahoo Finance API and plotly to desplay analysis"

    },
    {
      id: 3,
      src: movie,
      url: "https://front-media.vercel.app/",
      code: "https://github.com/berhane02/front-media",
      desc: "FullStack movie review webApp using React, javascript, html, CSS frontEnd and Springboot with MongoDB backend"

    },
    {
      id: 4,
      src: youtube,
      url: "https://github.com/berhane02/Data-Engineering/tree/main/EndToEndYoutubeDataAnalysis",
      code: "https://github.com/berhane02/Data-Engineering/tree/main/EndToEndYoutubeDataAnalysis",
      desc: "End to End youtube data analysis using AWS cloud services S3, pySpark, Glue, Lambda, AWS Athena and QuickSight"

    }*/
  ];
  return (
    <div
      name="portfolio"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen h-full justify-center pt-20"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-ful h-full md:">
        <div className="pb -8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Portfolio
          </p>
          <p className="py-6"> check out some of my work right here</p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-12 sm:px-0">
          {porofolios.map(({ id, src, url, code, desc }) => (
            <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
              />
              <div><p className="text-gray-300 px-3">
                {desc}</p></div>
              <div className="flex items-center justify-center">
                <a href={url} target="_blank" rel="noreferrer">
                  <button
                    className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                    
                  >
                    Demo
                  </button>
                </a>
                <a href={code} target="_blank" rel="noreferrer">
                  <button 
                  className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"
                  >
                    Code
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
