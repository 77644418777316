import NavBar from "./Components/NavBar"
import Home from "./Components/Home";
import SocialLinks from "./Components/SocialLinks";
import About from "./Components/About";
import Portfolio from "./Components/Portfolio";
import Experience from "./Components/Experience";
import Contact from "./Components/Contact"
import Certification from "./Components/Certification";

function App() {
  return (
    <div >
          <div >
     <NavBar />
     <Home />
     <SocialLinks />
     <About />
     <Portfolio/>
     <Experience/>
     <Certification/>
     <Contact/>
    </div>
    </div>

  );
}

export default App;
