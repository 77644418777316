import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { BiSolidDetail } from "react-icons/bi";
import {Link} from "react-scroll"


const SocialLinks = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          LinkedIn
          <FaLinkedin size={40} />
        </>
      ),
      href: "https://www.linkedin.com/in/yemane-berhane-996691153/",
      style: "rounded-tr-md",
    },
    {
      id: 2,
      child: (
        <>
          GitHub
          <FaGithub size={40} />
        </>
      ),
      href: "https://github.com/berhane02",
      style: "rounded-tr-md",
    },
    {
      id: 3,
      child: (
        <>
          Mail <MdOutlineMail size={40} />
        </>
      ),
      href: '',
      style: "rounded-tr-md",
      to: "contact"
    },
    {
      id: 4,
      child: (
        <>
          Resume
          <BiSolidDetail size={40} />
        </>
      ),
      href: "/YemaneBerhaneResume.pdf",
      style: "rounded-tr-md",
      download: true,
    },
  ];
  return (
    <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
      <ul>
        {links.map(({ id, child, href, style, download,to }) => (
          <li
            key={id}
            className={
              "flex justify-between items-center w-40 h-14 px-4 ml-[-103px] hover:ml-[-10px] hover:rounded-md duration-300 bg-cyan-950" +
              " " +
              style
            }
          >
            
            <a
              href={href}
              className="flex justify-between items-center w-full text-white"
              download={download}
              target="_blank"
              rel="noreferrer"
            >
              <Link to={to} smooth duration={500}>
              {to}
            </Link>
              {child}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialLinks;
