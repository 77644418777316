import React from "react";
import PortoImage from "../assets/pp.jpg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {Link} from "react-scroll"

const Home = () => {
  return (
    <div
      name="home"
      className="bg-gradient-to-b from-black to-gray-800 w-full py-20 text-white md:h-screen"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            Hello...👋 My name is Yemane, I'm a Software and DevOps Engineer
          </h2>
          <p className="text-gray-300 py-20 max-w-md sm:text-2xl">
            I have years of industry experience providing enterprise client
            infrastructure solution. Involved in Design, architecting and
            developing microservice cloud native base applications
          </p>
          <div>
            <Link to="portfolio" smooth duration={500} className="text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
              Portoflio
              <span className="hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1"/>
              </span>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={PortoImage}
            alt="my profile"
            className="rounded-2xl mx-auto w-2/3 md:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
