import React from "react";
import sprintBoot from "../assets/spring-boot.png";
import k8 from "../assets/kubernetes.png";
import django from "../assets/django-python-logo.png";
import jScript from "../assets/Javascript.png";
import Azure from "../assets/Azure.png";
import gcp from "../assets/gcp.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import angular from "../assets/angular.png";
import typescript from "../assets/typescript.png";
import react from "../assets/React-icon.svg.png";
import aws from "../assets/aws.png";
import docker from "../assets/docker.png";
import github from "../assets/github.png";
import gitlab from "../assets/gitlab.png";
import jenkins from "../assets/jenkins.png";
import golang from "../assets/golang.png";
import mongodb from "../assets/mongodb.png";
import mysql from "../assets/mysql.png"
import postgrsql from "../assets/postgress.png"
import ansible from "../assets/ansible.png"
import terraform from "../assets/terraform.png"


const Experience = () => {
  const techs = [
    {
      id: 1,
      src: react,
      title: "React",
      style: "shadow-blue-500",
    },
    {
      id: 2,
      src: angular,
      title: "Angular",
      style: "shadow-red-500",
    },
    {
      id: 3,
      src: sprintBoot,
      title: "SpringBoot",
      style: "shadow-green-500",
    },
    {
      id: 4,
      src: python,
      title: "Python",
      style: "shadow-yellow-500",
    },
    {
      id: 5,
      src: node,
      title: "Node",
      style: "shadow-green-500",
    },

    {
      id: 6,
      src: golang,
      title: "Golang",
      style: "shadow-blue-500",
    },
    {
      id: 7,
      src: jScript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 8,
      src: typescript,
      title: "TypeScript",
      style: "shadow-blue-500",
    },
    {
      id: 9,
      src: django,
      title: "Django",
      style: "shadow-green-500",
    },
    {
      id: 10,
      src: k8,
      title: "Kubernetes",
      style: "shadow-blue-500",
    },
    {
      id: 11,
      src: docker,
      title: "Docker",
      style: "shadow-blue-500",
    },
    {
      id: 12,
      src: github,
      title: "GitHub",
      style: "shadow-gray-500",
    },
    {
      id: 13,
      src: gitlab,
      title: "GitLab",
      style: "shadow-red-500",
    },
    {
      id: 14,
      src: jenkins,
      title: "Jenkins",
      style: "shadow-rose-500",
    },
    {
      id: 15,
      src: terraform,
      title: "Terraform",
      style: "shadow-violet-500",
    },
    {
      id: 16,
      src: ansible,
      title: "Ansible",
      style: "shadow-rose-500",
    },
    
    {
      id: 17,
      src: aws,
      title: "AWS",
      style: "shadow-orange-500",
    },
    {
      id: 18,
      src: Azure,
      title: "Azure",
      style: "shadow-blue-500",
    }
    ,
    {
      id: 19,
      src: gcp,
      title: "GCP",
      style: "shadow-rose-500",
    },
    {
      id: 20,
      src: mongodb,
      title: "mongodb",
      style: "shadow-green-500",
    },
    {
      id: 21,
      src: mysql,
      title: "mysql",
      style: "shadow-blue-500",
    },
    {
      id: 22,
      src: postgrsql,
      title: "postgrsql",
      style: "shadow-blue-500",
    },
    
  ];
  return (
    <div
      name="experience"
      className="bg-gradient-to-b from-gray-800 to-black w-full md:h-screen h-full top-0 pt-16"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Exprince
          </p>
          <p className="py-6">These are the technology i have worked with</p>
        </div>
        <div className="w-full grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-8 text-center py-8 px-10 sm:px-0">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 h-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
