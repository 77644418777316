import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const About = () => {
  const skills = [
    {
      id: 1,
      skill:
        "Developed fullStack application using MERN stack, SprintBoot and Django ",
    },
    {
      id: 1,
      skill:
        "Automation and orchestration tools such as Terraform, Jenkins, Ansible, and GitLab",
    },
    {
      id: 2,
      skill:
        "Containerization tools such as Docker and Kubernetes",
    },
    {
      id: 3,
      skill:
        "Successfully created and maintained automated (CI/CD) pipelines",
    },
    {
      id: 4,
      skill:
        "Developing and providing support apps in AWS, Azure, Google Cloud Platform (GCP)",
    },
    {
      id: 5,
      skill:
        "Monitoring tools such as Datadog, Prometheus, Grafana and Kibana... ",
    },
    {
      id: 5,
      skill:
        "Experience working on various DevOps technologies stack and tools like Kubernetes, Docker, Ansible, Prometheus, Grafana, AWS EKS, Jenkins, GIT, GitHub, GitLab, Terraform  ",
    },
    {
      id: 5,
      skill:
        "Ability to adaptation to situation quickly with good analytical skills and quick learner  ",
    },
    {
      id: 5,
      skill:
        "Design and implement network security controls for AWS edge services and monitoring and alerting to address security events and threats. ",
    },
    {
      id: 5,
      skill:
        "Good understand of infrastructure and cloud network TCP/UDP protocols, firewalls, DNS, WAF and Content Delivery",
    },
  ];
  return (
    <div
      name="about"
      className="w-full bg-gradient-to-b from-gray-800  to-black text-white gap-2 md:h-screen justify-end pt-auto min-h-0"
    >
      <div className="max-w-screen-lg p-8 mx-auto flex flex-col justify-center h-full">
        <div className="pb-8">
          <h3 className="text-4xl font-bold inline border-b-4 border-gray-500"> About Me </h3>
         
          <p className="text-gray-300 py-20  sm:text-2xl">I am a passionate Software Engineer and DevOps 
            engineer with six years of experience in designing, 
            implementing, and managing complex infrastructures. 
            Expertice and Proficient in multiple DevOps tools and techniques.
            Extensive experience in automation, Software Development and cloud developments utilizing AWS, Azure, and Google cloud services to Develope, Automation, Configuration, Management, and Deploy large-scale applications</p>
        </div>
        <div className="text-4xl font-bold inline border-b-4 border-gray-500">
          skills
        </div>
        <ul className="sm:text-xl md:text-xl">
        {skills.map(({ id, skill}) => (
            <li key={id} className="text-white w-fit px-6 py-3 my-2 flex items-center">
            <span>
              <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
            </span>
            {skill}
          </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default About;
