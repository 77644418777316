import React from "react";
import awsp from "../assets/AWSP.png";
import awsdevop from "../assets/AWDevOp.png";
import awsecurity from "../assets/AWSecurity.png";
import kubernets from "../assets/kubernetes.png";
/*import terraform from "../assets/terraform.png"
import azureAdmin from "../assets/azureAdmin.png"
import gcp from "../assets/gcp-clound-enginer.png"
*/
const Certification = () => {
  const porofolios = [
    {
        id: 6,
        src: kubernets,
        url: "https://www.credly.com/badges/e134abec-8a17-4b5a-a07b-c8e89e27a31e/public_url",
        style: "shadow-blue-800",
      },
    {
      id: 1,
      src: awsp,
      url: "https://www.credly.com/badges/8e402085-b4fc-4770-8df1-adcf09656d9c/public_url",
      style: "shadow-cyan-500",
    },
    {
      id: 2,
      src: awsdevop,
      url: "https://www.credly.com/badges/b8f3935c-9674-4867-99ae-8cad22f05e73/public_url",
      style: "shadow-cyan-500",
    },
    {
      id: 3,
      src: awsecurity,
      url: "https://www.credly.com/badges/8eff8532-3eca-4789-878f-188f7cbf1da2/public_url",
      style: "shadow-violet-700",
    }/*,
    {
      id: 4,
      src: awsdev,
      url: "https://www.credly.com/badges/863f0d32-553d-4504-9c43-1b35d36713a7/public_url",
      style: "shadow-blue-700",
    },
    {
      id: 5,
      src: awssystem,
      url: "https://www.credly.com/badges/658c3596-1389-44ba-85f1-76c53b49e9d5/public_url",
      style: "shadow-blue-700",
    },{
      id: 7,
      src: awssa,
      url: "https://www.credly.com/badges/b81043cb-2230-44c1-bf46-e7ec9b2ea795/public_url",
      style: "shadow-blue-700",
    },{
      id: 8,
      src: terraform,
      url: "https://www.credly.com/badges/b81043cb-2230-44c1-bf46-e7ec9b2ea795/public_url",
      style: "shadow-violet-500",
    },{
      id: 7,
      src: azureAdmin,
      url: "https://www.credly.com/badges/b81043cb-2230-44c1-bf46-e7ec9b2ea795/public_url",
      style: "shadow-blue-700",
    },{
      id: 8,
      src: gcp,
      url: "https://www.credly.com/badges/b81043cb-2230-44c1-bf46-e7ec9b2ea795/public_url",
      style: "shadow-gray-200",
    }*/
    
  ];
  return (
    <div
      name="certification"
      className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen"
    >
      <div className="max-w-screen-lg p-8 mx-auto flex flex-col justify-center w-ful h-full">
        <div className="pb -8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Certification
          </p>
          <p className="py-6"> View my Certification</p>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-12">
          {porofolios.map(({ id, src, url,style }) => (
            <a href={url} target="_blank" rel="noreferrer">
              <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                <img
                  src={src}
                  alt=""
                  className="w-15 h-15 mx-auto"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certification;
